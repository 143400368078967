.sign-kit-button {
    background: none;
    border: none;
    cursor: pointer;
}
.color-container {
    display: flex;
}
.color-container-inner {
    display: grid;
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 1em;
}
.color-menu {
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
    padding: 3px;
    z-index: 5;
    position: absolute;
    left: 23em;
    margin-top: 5em;
    display: inline-flex;
}
.color-menu-rows {
    position: relative;
    display: inline-flex;
}
.color-text-display {
    font-weight: 600;
    text-align: center;
}
.color-btn-display {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(255, 255, 255, 0); */
    pointer-events: all;
    border-radius: 3%;
    text-align: center;
    align-content: center;
    font-weight: 600;
    font-size: large;
  }
div {
    top: 0px !important;
}