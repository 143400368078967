.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  width: 70vh;
  align-items: center;
  pointer-events: none;
}
/* 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.loginbutton{
  height: 5vmin;
  width: 10vh;
  align-items: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.price-change-table tr:nth-child(odd) td {
  background-color: #004a631f;
}
.price-change-table tr:nth-child(even) td {
  background-color: white;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
  background-color: #004B63;
  color: white;
  padding: 8px;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
  background-color: #004B63D4;
}

/* This is the carrot symbol color. Gray = inactive, white = active; */
.ant-table-wrapper .ant-table-column-sorter,
.ant-table-wrapper .ant-table-column-sorters:hover .ant-table-column-sorter {
  color: gray;
}

.ant-table-wrapper .ant-table-column-sorter-up.active, .ant-table-wrapper .ant-table-column-sorter-down.active {
  color: white;
}

table {
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
}