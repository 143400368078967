@media (max-width: 1400px) {
    .ingested-time {
        display: none !important;
    }
}

@media (max-width: 1120px) {
    .carters-promo {
        display: none !important;
    }
}

@media (max-width: 960px) {
    .read-write-toggle {
        display: none !important;
    }
}