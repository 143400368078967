@media only screen and (max-width: 1450px) {
    .ant-table table {
        table-layout: auto !important;
    }
  }

@media only screen and (max-width: 1270px) {
    .ant-table-cell {
        padding-left: 0em !important;
    }
}

@media only screen and (max-width: 1150px) {
    .ant-table-cell {
        padding-left: 0em !important;
        padding-right: 0em !important;
    }
    [aria-label="filter"] {
        display: none !important;
    }
}